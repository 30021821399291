import React from 'react'
import './Start.css'
function Start() {
  return (
    <div className='start-box'>
        <h1>Get started with Homyz</h1>
    <p>Subscribe and find super attractive<br/> price quotes from us.
    Find your residence soon</p>
    <button>Get Start</button>
    </div>
  )
}

export default Start
import React from 'react'
import './Companies.css'
function Companies() {
  return (
    <div className='copmanies-box'>
        <img src='https://real-estate-web.pages.dev/prologis.png' alt=''></img>
        <img src='https://real-estate-web.pages.dev/tower.png' alt=''></img>
        <img src='https://real-estate-web.pages.dev/equinix.png' alt=''></img>
        <img src='https://real-estate-web.pages.dev/realty.png' alt=''></img>
    </div>
  )
}

export default Companies
export const SliderSetting={
    slidesPerView:1,
    spaceBetween:50,
    // centeredSlides: true,
    breakpoints:{
        450:{
            slidesPerView:1,
        },
        600:{
            slidesPerView:2,
            
        },
        850:{
            slidesPerView:3,
        },
        1150:{
            slidesPerView:4,
        }

    }

    
}